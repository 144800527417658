<template>
  <b-container class="mb-7">
    <Alert variant="red" icon="exclamation" v-if="errorMessage">{{ errorMessage }}</Alert>
    <h1 class="px-3">Customers</h1>
    <div class="container-search flex flex-row flex-wrap items-center justify-between">
      <div class="px-4 flex flex-row xs:flex-wrap md:flex-nowrap flex-1 items-center w-full">
        <b-form-input
          v-model="form.input"
          placeholder="Enter search"
          debounce="300"
          class="md:mr-4 md:max-w-xs"
        ></b-form-input>
        <div class="flex flex-row items-center justify-between xs:w-full xs:mt-3 sm:w-full md:mt-0 md:flex-1">
          <div class="flex">
            <CreateCustomer @createCustomer="refreshTable" />
            <ImportCustomersModal @importCustomers="refreshTable" class="ml-4" v-if="showImportModal" />
            <FileImporterModal
              v-if="showImportModalV2"
              service="customer"
              sampleUrl="https://rafflebox-docs.s3.ca-central-1.amazonaws.com/customer-import-example.csv"
              class="ml-3"
            />
            <DownloadFile
              :retrieveData="exportCustomers"
              filename="customers.csv"
              @error="exportError"
              v-if="enableExportCustomers"
              class="ml-4"
            >
              <template #default="{ downloadFile }">
                <BaseButton
                  variant="secondary-outline"
                  icon="arrow-down-to-line"
                  :loading="buttonLoading"
                  @click.native="downloadFile"
                >
                  Export
                </BaseButton>
              </template>
            </DownloadFile>
          </div>
          <b-form-checkbox v-model="active" class="mt-1" switch>Show active</b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="overflow-x-hidden">
      <div class="col">
        <GenericTable
          :columns="columns"
          :data="data"
          :pagination="pagination"
          :loading="loading"
          :actions="true"
          @sort="sort"
          @updateCurrentPage="updateCurrentPage"
          class="customers"
        >
          <template #phone="{ row }">
            {{ row.phone ? formatPhone(row.phone) : '' }}
          </template>
          <template #createdAt="{ row }">
            <span
              v-b-tooltip.hover
              :title="
                row.createdAt
                  ? formatDateTime(parseISO(row.createdAt), {
                      format: 'dateTime',
                      timeZone: timeZone,
                      displayTimeZone: true
                    })
                  : ''
              "
              class="dashline"
            >
              {{ row.createdAt ? formatDateTime(parseISO(row.createdAt)) : '' }}
            </span>
          </template>
          <template #actions="{ row }">
            <router-link :to="{ name: 'Customer', query: { id: row.id } }" class="edit-link">
              <span><i class="fa-regular fa-pen-to-square"></i></span>
            </router-link>
          </template>
        </GenericTable>
      </div>
    </div>
  </b-container>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import GenericTable from '@/components/GenericTable';
import CustomerServiceV2 from '@/lib/customer-service-v2';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import CreateCustomer from '@/components/CreateCustomer.vue';
import ImportCustomersModal from '@/components/modals/ImportCustomersModal.vue';
import DownloadFile from '@/components/DownloadFile.vue';
import Alert from '@/components/ui/Alert.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import FileImporterModal from '@/components/modals/FileImporterModal.vue';

export default {
  name: 'Customers',
  components: {
    Alert,
    GenericTable,
    CreateCustomer,
    ImportCustomersModal,
    DownloadFile,
    BaseButton,
    FileImporterModal
  },
  watch: {
    'form.input': function () {
      this.updateCurrentPage(0);
      this.loadCustomers();
    },
    active: function () {
      this.updateCurrentPage(0);
      this.loadCustomers();
    }
  },
  data() {
    return {
      errorMessage: null,
      loading: false,
      buttonLoading: false,
      form: {
        input: ''
      },
      active: true,
      timeZone: '',
      data: [],
      columns: [
        {
          name: 'firstName',
          label: 'First Name',
          sortable: true
        },
        {
          name: 'lastName',
          label: 'Last Name',
          sortable: true
        },
        {
          name: 'phone',
          label: 'Phone Number'
        },
        {
          name: 'email',
          label: 'Email'
        },
        {
          name: 'createdAt',
          label: 'Created',
          sortable: true
        }
      ],
      pagination: {
        total: 0,
        page: 0,
        pageSize: 10,
        sortBy: 'createdAt',
        sortDir: 'desc'
      },
      enableImportCustomers: false,
      enableExportCustomers: false,
      showCustomerImporter: true
    };
  },
  computed: {
    showImportModal() {
      return !this.$store.state.organization.productGoldrushEnabled && this.enableImportCustomers;
    },
    showImportModalV2() {
      return !this.$store.state.organization.productGoldrushEnabled && this.showCustomerImporter;
    }
  },
  async beforeMount() {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (browserTimeZone) {
      this.timeZone = browserTimeZone;
    } else {
      this.timeZone = 'UTC';
    }

    const viewGoldrushRaffles = await unleashFeatureEnabled(UnleashKeys.ViewGoldrushRaffles);
    this.enableImportCustomers = await unleashFeatureEnabled(UnleashKeys.ImportCustomers);
    this.enableExportCustomers = await unleashFeatureEnabled(UnleashKeys.ExportCustomers);
    this.showCustomerImporter = await unleashFeatureEnabled(UnleashKeys.ShowCustomerImporter);

    if (viewGoldrushRaffles) {
      this.columns.unshift({
        name: 'playerNumber',
        label: 'Player Number',
        sortable: true
      });
    }

    await this.loadCustomers();
  },

  methods: {
    async refreshTable() {
      this.loading = true;
      await this.loadCustomers();
    },
    updateCurrentPage(page) {
      this.pagination.page = page;
      this.loadCustomers();
    },
    sort(sortBy, sortDir) {
      this.pagination.sortBy = sortBy;
      this.pagination.sortDir = sortDir;
      this.loadCustomers();
    },
    async loadCustomers() {
      this.loading = true;

      try {
        const sessionUser = await getAuth().sessionUser();
        const params = {
          organizationId: sessionUser.organizationUuid,
          active: this.form.active,
          search: this.form.input.trim() || undefined,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          sortBy: this.pagination.sortBy || undefined,
          sortDir: this.pagination.sortDir
        };
        if (this.active) {
          params.active = true;
        }

        this.data = [];

        const customers = await CustomerServiceV2.listCustomers(params);

        this.pagination = customers.pagination;

        this.data = customers.data.map((customer) => {
          return {
            playerNumber: customer.playerNumber,
            firstName: customer.firstName,
            lastName: customer.lastName,
            phone: customer.phone,
            email: customer.email,
            createdAt: customer.createdAt,
            active: customer.active,
            address: customer.address,
            city: customer.city,
            country: customer.country,
            id: customer.id,
            postal: customer.postal,
            province: customer.province
          };
        });

        this.loading = false;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
        this.loading = false;
      }
    },

    async exportCustomers() {
      this.buttonLoading = true;
      try {
        const response = await CustomerServiceV2.exportCustomers(this.$store.state.organization.id);
        return response;
      } catch (error) {
        this.buttonLoading = false;
        throw error;
      } finally {
        this.buttonLoading = false;
      }
    },

    async exportError(error) {
      this.errorMessage = this.parseError(error).message;
    }
  }
};
</script>

<style>
.customer-table thead th,
.customer-table td {
  white-space: nowrap;
  vertical-align: middle;
}
</style>

<style scoped>
.container-search {
  padding-bottom: 1rem;
}

.edit-link {
  color: black;
}

.edit-link:hover {
  color: #59b55e;
}
.dashline {
  border-bottom: 1px dashed;
}
</style>
